.custom_popup {
  background: #1a1a1a;
  border-radius: 20px;
  border: 0.5px solid #b6b6b6;
  /* box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.47); */
  max-width: 800px;
  padding: 2rem 0px;
  margin: auto;
}

.first_container {
  width: 38%;
  max-width: 600px;
  margin: auto;
}

.background_popup {
  /* background: #111331; */
  /* linear-gradient(180deg, rgba(0, 0, 0, 0.70) 0%, rgba(2.74, 23.13, 25.91, 0.70) 100%); */
  /* border: 1px rgba(121, 78, 255, 0.64) solid; */
  background: linear-gradient(180deg, rgba(63, 45, 132, 0.5) 0%, rgba(36, 26, 73, 0) 100%),
    rgba(17, 17, 21, 0);
  border-right: 1px solid #1a2045;
  border-bottom: 1px solid #1a2045;
  backdrop-filter: blur(28.09789276123047px);
}

.custom_popup section {
  padding: 0px 1rem;
}

.form_link_description {
  font-size: 12px;
  line-height: 16px;
  margin-top: 0.8rem;
  color: rgba(255, 255, 255, 0.7);

  text-align: center;
}

.form_link_description a {
  color: rgba(255, 255, 255, 0.7) !important;
}

.swap_container {
  display: flex;
  flex-direction: column;
  min-height: calc(100vh);
  /* background-color: #111; */
  background-image: url("../../public/base_bg.svg");
  background-size: cover;
  background-repeat: no-repeat;
  /* padding: 5rem 0; */
  overflow: hidden;
  position: relative;
}

/* .swap_container_gradient { */
/* width: 100%;  */
/* height: 100%;  */
/* min-height: calc(100vh - 56px); */
/* mix-blend-mode: lighten;  */
/* background: linear-gradient(180deg, rgba(121, 78, 255, 0) 0%, #794EFF 50%, rgba(121, 78, 255, 0) 100%); */
/* position: relative; */
/* } */

/* .swap_header {
  font-style: normal;
  font-weight: 700;
  font-size: 32px;
  line-height: 48px;
  letter-spacing: 0px;
  color: #000000;
} */
.testnet_container {
  font-size: 16px;
  font-weight: 600;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  padding-right: 2rem;
  background-color: #fff;
  position: absolute;
  top: 2rem;
  right: 2rem;
  padding: 0.5rem 1rem;
}

.swap_subheading {
  /* font-size: 20px; */
  /* line-height: 28px; */
  /* font-weight: 600; */
  /* margin-top: 24px; */
  color: #ffffff;
  font-size: 14px;
  font-family: Syne;
  font-weight: 600;
  word-wrap: break-word;
  text-align: center;
}

.popup_header {
  font-size: 20px;
  font-weight: 600;
  color: #fff;
  padding-top: 0.5rem;
  position: relative;
  /* display: flex;
  justify-content: center;
  align-items: center; */
}

.popup_heading_icons {
  position: absolute;
  right: 20px;
  font-size: 20px;
  font-weight: 600;
  opacity: 0.5;
  cursor: pointer;
}

.swap_border {
  /* width: 100%; 
  height: 100%;  */
  background: rgba(255, 255, 255, 0.1);
  border-radius: 35px;
  /* border: 0.68px rgba(255, 255, 255, 0.1) solid; */
}

.form_section {
  color: #22262a;
  /* margin-top: 8px; */
  position: relative;
}

.label {
  font-weight: 500;
  font-size: 14px;
  line-height: 20px;
  /* margin-top: 1rem; */
  color: #b6b6b6;
}

.label span {
  font-weight: 700;
  font-size: 11px;
  text-align: end;
}

.form_section .content {
  font-size: 11px;
  text-align: end;
}

.form_section .content span {
  font-size: 11px;
  font-weight: 700;
}

.form_section .content a {
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
}

.amount_input {
  width: 100%;
  padding: 6px 12px;
  /* background: rgba(255, 210, 0, 0.12); */
  /* border-radius: 6px; */
  /* border: 0.606px solid rgba(255, 255, 255, 0.1); */
  font-size: 43px;
  color: #ffffff;
  font-weight: 600;
}

.swap_icon {
  display: flex;
  justify-content: center;
  /* font-size: 12px; */
  color: #111331;
  /* opacity: 0.7; */
  /* margin-top: 0.5rem; */
}

.sub_yellow {
  color: #ffb800;
}

.sub_violet {
  color: #4d3fee;
}

.form_section button {
  position: absolute;
  text-transform: uppercase;
  right: 20px;
  top: 5px;
  height: 80%;
  font-size: 16px;
  font-weight: 700;
  padding: 6px;
  /* width: 12rem !important; */
  /* background: #FFFFFF; */
  color: #000;
  /* border-radius: 0px; */
}

.form_section .form_select option {
  padding: 2rem;
}

.eth_address_container {
  /* background: rgba(0, 0, 0, 0.1); */
  border-radius: 4.347px;
  border: 0.724px solid rgba(255, 255, 255, 0.1);
  padding: 12px 16px;
  display: flex;
  justify-content: center;
  font-size: 16px;
  font-weight: 500;
  color: #b6b6b6;
  margin-top: 1rem;
}

.eth_address_container .text {
  /* color: #000; */
  font-size: 12px;
  word-wrap: break-word;
}

.connect_wallet_button,
.cancel-wallet-button {
  margin-top: 24px;
}

.connect_wallet_button img,
.cancel-wallet-button img {
  height: 20px;
  width: 20px;
}

.connect_wallet_button button,
.cancel-wallet-button button {
  /* width: 100%; */
  /* border-radius: 8px; */
  /* font-size: 20px; */
  /* background-color: #ffd200; */
  /* background: #FFFFFF; */
  /* border: 1px solid #4C3EEE; */
  /* box-shadow: 3px 3px 0px #FFD200; */
  /* color: #000; */
  /* font-weight: 700; */
  padding: 0.5rem 1rem;
}

.connect_wallet_button .disabled {
  background: rgba(239, 239, 239, 0.6);
  box-shadow: 3px 3px 0px #dadada;
}

.initiate_button:hover {
  /* background: #4D3FEE; */
  /* color: #fff; */
}

.form_section button img {
  height: 30px;
  width: 30px;
}

.initiate_bridge_cta {
  display: flex;
  flex-direction: column;
  color: #b6b6b6;
  align-items: center;
  margin-top: 1rem;
}

@media only screen and (max-width: 1280px) {
  .first_container {
    width: 70%;
  }
}

@media only screen and (max-width: 640px) {
  .first_container {
    width: 96%;
  }

  .swap_mobile_container {
    border-top-left-radius: 22px;
    border-top-right-radius: 22px;
    background: linear-gradient(172deg, rgba(0, 0, 0, 0.7) 6.13%, rgba(3, 23, 26, 0.7) 100.79%);
    backdrop-filter: blur(33.75px);
  }
  .swap_mobile {
    display: flex;
    height: calc(100vh - 100px);
  }

  .token_name_mob {
    background: linear-gradient(93deg, #794eff 0.94%, #fff 123.02%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
  }

  .swap_container {
    /* padding: 3rem 0px; */
    /* background-image: url('../../public/backgroundImage.png'); */
    background-image: none !important;
    background-repeat: no-repeat;
  }

  .eth_address_container .text {
    font-size: 8px;
  }

  /* .custom_popup {
    width: 95%;
    margin: auto;
  } */

  .form_section .amount_input {
    padding: 10px;
    /* font-size: 16px; */
  }

  .popup_header {
    font-size: 12px;
    padding: 0.5rem 0px;
  }

  .swap_icon {
    margin-bottom: 0px;
  }

  .swap_header {
    color: #fff;
    font-size: 20px;
    line-height: 24px;
  }

  .swap_subheading {
    font-size: 20px;
    line-height: 30px;
  }

  .popup_heading_icons {
    right: 12px;
    font-size: 14px;
  }

  .custom_popup section {
    padding: 0.5rem;
  }

  .form_section {
    margin-top: 8px;
  }

  .label {
    margin-top: 0.5rem;
    font-size: 12px;
  }

  .form_section input {
    padding: 6px;
  }

  .form_section .form_select {
    right: 12px;
    height: 80%;
    top: 5px;
    width: 100px;
    font-size: 12px;
  }

  .form_section .form_select option {
    padding: 1rem;
  }

  .connect_wallet_button,
  .cancel-wallet-button {
    margin-top: 12px;
    margin-bottom: 0px;
  }

  .connect_wallet_button button,
  .cancel-wallet-button button {
    padding: 0.5rem;
  }

  .eth_address_container {
    font-size: 12px;
    line-height: 18px;
    padding: 12px;
  }

  .form_link_description {
    font-size: 12px;
    line-height: 16px;
    display: flex;
    gap: 0.25rem;
    align-items: center;
    /* justify-content: center; */
  }

  .custom_popup section {
    padding: 0px 0.5rem;
  }
}

.convert_details {
  font-size: 12px;
  padding: 4px 12px !important;
  color: #000;
  font-weight: 700;
}

.suggestions {
  position: absolute;
  top: 50px;
  right: 0px;
  z-index: 999;
  cursor: pointer;
  max-height: 200px;
  width: 39%;
  overflow: auto;
}

.suggestions > div {
  padding: 15px 25px;
  border-bottom: 2px solid #2d2e36;
  transition: all 0.1s ease;
}

.suggestions > div:hover {
  background-color: #dbdbdb !important;
  color: #000000;
}

.suggestions > div,
.form-control-auto input {
  font-size: 14px !important;
  background-color: #1e1f26;
  color: #ffffff;
}

.bridge_logo_container {
  display: flex;
  gap: 0.5rem;
}

.logo_image {
  height: 30px;
  width: 30px;
}

.logo_heading {
  font-size: 20px;
  line-height: 28px;
  font-weight: 700;
  color: #ffffff;
}

.bridge_color {
  color: #794eff;
}
