.pending_container {
  /* width: 100%; */
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.entries_container {
  width: 90%;
  max-height: 50vh;
  overflow-y: scroll;
  margin-top: 1.5rem;
  background: #1a1a1a;
  border-radius: 20px;
  border: 0.5px solid #b6b6b6;
  color: #b6b6b6;
  /* background: #FFFFFF;
    border: 1px solid #DADADA;
    box-shadow: 6px 6px 0px rgba(255, 255, 255, 0.47); */
  padding: 1.5rem 0;
}

.entry_heading {
  color: #ffff;
  font-weight: 700;
  letter-spacing: 0px;
  font-size: 24px;
  line-height: 44px;
  margin-left: 3rem;
}

.table_head {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  margin-top: 1rem;
}

.thead_name {
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.thead_name_1 {
  text-align: left;
  font-size: 20px;
  line-height: 28px;
  font-weight: 600;
}

.table_rows {
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr;
  place-items: center;
  margin-top: 1rem;
  padding: 0.5rem 0px;
}

.table_rows:hover {
  background: #e6e6e6;
}

.thead_col {
  font-size: 16px;
  line-height: 28px;
  font-weight: 600;
}

.claim_container {
  width: 100%;
  border: 1px solid #5e4b92;
  background: linear-gradient(180deg, rgba(63, 45, 132, 0.5) 0%, rgba(36, 26, 73, 0) 100%),
    rgba(17, 17, 21, 0);
  /* box-shadow: -44px 120px 80px -72px #5E4B92 inset, -44px -120px 80px -72px #5E4B92 inset; */
  /* backdrop-filter: blur(28.09789276123047px); */
}

@media only screen and (max-width: 640px) {
  .entries_container {
    width: 95%;
  }

  .table_head {
    grid-template-columns: 0.5fr 1fr 1.5fr 1fr;
  }

  .table_rows {
    grid-template-columns: 0.5fr 1fr 1.5fr 1fr;
  }

  .entry_heading {
    margin-left: 1rem;
    font-size: 16px;
    line-height: 24px;
  }

  .table_head {
    margin-top: 0.5rem;
  }

  .thead_name_1 {
    font-size: 10px;
    line-height: 16px;
  }

  .thead_name {
    font-size: 10px;
    line-height: 16px;
  }

  .thead_col {
    font-size: 10px;
  }

  .pending_button {
    font-size: 18px;
    line-height: 24px;
  }
}
