/* @import url('https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;0,900;1,900&family=Poppins:wght@200&family=Red+Rose:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&display=swap'); */
@import url("https://fonts.googleapis.com/css2?family=Lato:ital,wght@0,700;0,900;1,900&family=Poppins:wght@200&family=Red+Rose:wght@300&family=Roboto:ital,wght@0,100;0,300;0,400;0,500;0,700;0,900;1,300;1,400;1,500;1,700&family=Syne&display=swap");

body {
  margin: 0;
  /* font-family: 'Red Hat Display', sans-serif !important; */
  font-family: "Syne" !important;
  /* background: url("../../public/background.png"), rgba(0, 0, 0, 0.1); */
  /* font-family: "Inter", sans-serif; */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #1c1c1c !important;
  background-color: #0a080f;
  background: #0a080f;
  background-image: url("../assets/bg-image.png");
  background-repeat: no-repeat;
  background-size: cover;
  letter-spacing: 1px !important;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New", monospace;
}

ul,
li {
  margin: 0 !important;
  padding: 0 !important;
}

/* a{
  text-decoration: none !important;
} */
.cursor-pointer:hover {
  cursor: pointer;
}

input[type="password"] {
  width: 100%;
  padding: 8px 10px;
  box-sizing: border-box;
  border: none;
  border-bottom: 2px solid white;
  color: white;
  background: transparent;
  font-size: 14px;
}

input[type="password"]::placeholder {
  color: white;
}

.footer-icon {
  height: 60px;
  width: 60px;
}

.c-ptr {
  cursor: pointer;
}

.bg-blur {
  backdrop-filter: blur(2px);
}

.custom-scroller::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
  border-radius: 10px;
  background-color: transparent;
}

.custom-scroller::-webkit-scrollbar {
  width: 7px;
  height: 7px;
  background-color: transparent;
}

.custom-scroller::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
  background-color: #555;
}

.border-theme {
  border: 1px solid #edab00;
}

.page-height {
  height: calc(100% - 60px);
}

.border-gray {
  border-color: #494949 !important;
}

.feature-container {
  margin: 0 12em !important;
}

@media only screen and (max-width: 600px) {
  .feature-container {
    margin: 0 4em !important;
  }
}

.text-dimmed {
  color: #464a4b;
}

.feature-container-header {
  font-weight: 600;
  background: -webkit-linear-gradient(226.63deg, #ffb800 0%, #ffcb43 50%, #edab00 100%);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.transform-105:hover {
  transform: scale(1.05, 1.05);
}

/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  -moz-appearance: textfield;
}

input[type="search"] {
  -webkit-appearance: none;
  -webkit-border-radius: 0;
}

* {
  box-sizing: border-box;
  line-height: normal;
}

body {
  font-family: "Roboto", sans-serif !important;
  margin: 0;
  padding: 0;
}

table {
  border-spacing: 0;
  width: 100%;
}

.common-select {
  z-index: 10000;
}

.react-datepicker-popper {
  z-index: 100000 !important;
}

.drawable-icon {
  position: absolute;
  margin: auto;
  z-index: 1000;
}

.input-wrap {
  position: relative;
}

option {
  color: #000;
}

.table-wrap {
  overflow: auto;
}

input:focus {
  outline: none;
}

.ReactModal__Body--open {
  overflow: hidden;
}

.slider-indicator:first-child {
  margin-left: 0;
}

.slider-indicator:last-child {
  margin-right: 0;
}

.rc-drawer-content-wrapper {
  width: 100% !important;
}

.rc-drawer-right .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-end;
}

.rc-drawer-left .rc-drawer-content-wrapper {
  display: flex;
  justify-content: flex-start;
}

.ReactModal__Overlay {
  z-index: 1051;
}

ul li a {
  display: block;
}

.mobile-menu div {
  height: 1.5px;
  width: 20px;
  background-color: #000;
  margin: 4px 0;
}

.mobile-menu {
  display: none;
  cursor: pointer;
}

.header-row {
  align-items: center;
  justify-content: space-between;
  display: flex;
}

@media only screen and (min-width: 200px) and (max-width: 550px) {
  .mobile-menu {
    display: block;
  }

  .header-row {
    width: 100% !important;
  }

  .display-block {
    display: block;
  }

  .display-none {
    display: none;
  }

  .common-row-list > ul {
    padding-left: 10px;
    padding-right: 10px;
    flex-wrap: wrap;
  }
}

.menu-close-icon {
  position: absolute;
  right: 16px;
  top: 16px;
}

.menu-overlay {
  width: 100%;
  height: 100%;
  background-color: #000;
  opacity: 0.7;
  position: fixed;
  left: 0px;
  top: 0px;
}

.close-line1 {
  transform: translate(0, 6px) rotate(-45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.close-line2 {
  transform: translate(0, 0) rotate(45deg) skewX(0) skewY(0) scaleX(1) scaleY(1);
}

.common-row-list > ul {
  display: flex;
  flex-direction: row;
  list-style-type: none;
  flex-grow: 1;
}

.common-column-list > ul {
  display: flex;
  flex-direction: column;
  list-style-type: none;
}

.common-column-list li {
  width: 100%;
}

/* scroll */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
  background: transparent;
}

/* Track */
::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: #6b6b6b;
  border-radius: 10px;
}

.dropdown {
  min-width: 100px;
}
.dropdownItems {
  box-shadow:
    0 10px 20px rgba(0, 0, 0, 0.19),
    0 6px 6px rgba(0, 0, 0, 0.23);
  background-color: #000;
  color: #ffffff !important;
  position: absolute;
  width: 100%;
  margin-top: 5px;
  display: flex;
  flex-direction: column;
  border-radius: 1rem;
  z-index: 1;
}
.dropdownItem {
  padding-top: 0px;
  padding-bottom: 0px;
  color: #ffffff !important;
  cursor: pointer;
}
.isVisible {
  visibility: visible;
}
.isHidden {
  visibility: hidden;
}

.Toastify__toast-container {
  z-index: 1000000 !important;
}

.two_million_conatiner {
  background-image: url("../assets/2moverlay.png");
}
